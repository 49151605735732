<birdz-card [isLoading]="isLoading" [isEmpty]="!hasPerimeter">
  <div card-title>
    {{ 'REMOTE_READ_POS' | transloco }}
    <button class="help" mat-icon-button (click)="help()">
      <mat-icon>info</mat-icon>
    </button>
  </div>
  <div card-subtitle>
    @if (stats?.date) { {{ 'DATE_AT' | transloco }} {{ (stats?.date ?? '') | formatDate }} }
  </div>
  <div card-empty>{{ "PLEASE_SELECT_PERIMETER" | transloco }}</div>
  <div card-body class="body">
    @if (stats?.date === null) {
    <div class="no-data">{{ 'NO_DATA_AVAILABLE' | transloco }}</div>
    } @else {
    <!-- total -->
    <div class="total">
      {{ 'REMOTE_READ_DEVICE_COUNT' | transloco }} {{ 'COLON' | transloco }} {{ total | formatNumber
      }}
      <div class="toggle">
        <mat-slide-toggle (change)="toggleView()" [(ngModel)]="isDetailed"
          >{{ 'DETAILED_VIEW' | transloco }}</mat-slide-toggle
        >
      </div>
    </div>
    <!-- chart -->
    @if (chartOptions) {
    <div class="chart-layout">
      <highcharts-chart
        [Highcharts]="Highcharts"
        (chartInstance)="setChartInstance($event)"
        [(update)]="updateFlag"
        class="chart"
        [options]="chartOptions"
      ></highcharts-chart>
      <div class="legend">
        <app-legend-item
          id="connected_functional"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'CONNECTED_FUNCTIONAL' | transloco }}"
          [subtitle]="subtitleConnectedFunctional"
        />
        <app-legend-item
          id="connected_non_functional"
          [forceDisplay]="true"
          [children]="['connected_non_functional_hs', 'connected_non_functional_nc']"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'CONNECTED_NONFUNCTIONAL' | transloco }}"
          [subtitle]="subtitleConnectedNonFunctional"
        />
        <app-legend-item
          id="connected_non_functional_hs"
          [level]="1"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'CONNECTED_NONFUNCTIONAL_HS' | transloco }}"
          [subtitle]="subtitleConnectedNonFunctionalHS"
        />
        <app-legend-item
          id="connected_non_functional_nc"
          [level]="1"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'CONNECTED_NONFUNCTIONAL_NC' | transloco }}"
          [subtitle]="subtitleConnectedNonFunctionalNC"
        />
        <app-legend-item
          id="not_connected"
          [chart]="chart"
          [update]="updateFlag"
          title="{{ 'NOT_CONNECTED' | transloco }}"
          [subtitle]="subtitleNotConnected"
        />
      </div>
    </div>
    }
    <!-- figures -->
    @if (stats) {
    <div class="figures">
      <div class="figure figure--ok">
        {{ (total === 0 ? 0 : ((stats.devices_connected_functional ?? 0) / total * 100)) |
        formatNumber: '0.0-1' }}% {{ 'OF_DEVICES_CONNECTED_FUNCTIONAL' | transloco }}
      </div>
      <div class="figure figure--ko">
        {{ (total === 0 ? 0 : ((stats.devices_not_connected ?? 0) / total * 100)) | formatNumber:
        '0.0-1' }}% {{ 'OF_DEVICES_NOT_CONNECTED' | transloco }}
      </div>
    </div>
    } }
  </div>
</birdz-card>
