import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService, Paginated, Period } from '@web/birdz-angular';
import { MapPds } from 'app/interfaces/map-pds.interface';
import { MapResponse } from 'app/interfaces/map-response.interface';
import { PdsRequest } from 'app/interfaces/pds-request.interface';
import { Pds } from 'app/interfaces/pds.interface';
import { format } from 'date-fns';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DashboardStats } from '../interfaces/dashboard-stats.interface';
import { IndexCollectionStats } from '../interfaces/index-collection-stats.interface';
import { Perimeter } from '../interfaces/perimeter.interface';

@Injectable({
  providedIn: 'root',
})
export class Api {
  statsUrl = `${environment.apiUrl}/stats`;
  indexCollectionRatesUrl = `${environment.apiUrl}/index-collection-rates`;
  mapUrl = `${environment.apiUrl}/map`;
  pdsIndexUrl = `${environment.apiUrl}/pds-index`;
  pdsUrl = `${environment.apiUrl}/pds`;

  constructor(
    private http: HttpClient,
    private cache: CacheService
  ) {
    this.cache.register(
      { method: 'post', url: this.statsUrl },
      { method: 'post', url: this.indexCollectionRatesUrl },
      { method: 'post', url: this.mapUrl },
      { method: 'post', url: this.pdsUrl },
      { method: 'post', url: this.pdsIndexUrl }
    );
  }

  fetchDashboardStatistics(perimeter: Perimeter): Observable<DashboardStats> {
    return this.http.post<DashboardStats>(this.statsUrl, { ...perimeter });
  }

  fetchIndexCollectionRates(
    perimeter: Perimeter,
    period: Period
  ): Observable<IndexCollectionStats> {
    return this.http.post<IndexCollectionStats>(this.indexCollectionRatesUrl, {
      ...perimeter,
      from: format(period.from as Date, 'yyyy-MM-dd'),
      to: format(period.to as Date, 'yyyy-MM-dd'),
    });
  }

  fetchPds(id: string): Observable<MapPds> {
    return this.http.get<MapPds>(`${environment.apiUrl}/pds/${id}`);
  }

  fetchPdsIndex(perimeter: Perimeter, params: PdsRequest): Observable<Paginated<Pds>> {
    return this.http.post<Paginated<Pds>>(`${environment.apiUrl}/pds-index`, {
      ...perimeter,
      ...params,
    });
  }

  fetchMap(perimeter: Perimeter): Observable<MapResponse> {
    return this.http.post<MapResponse>(this.mapUrl, perimeter);
  }
}
