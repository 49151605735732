import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Perimeter } from 'app/interfaces/perimeter.interface';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FilterOptions } from '../interfaces/filter-options.interface';
import { Filter } from '../interfaces/filter.interface';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private _filter$: BehaviorSubject<Filter> = new BehaviorSubject(<Filter>{
    contracts: [],
    geocodes: [],
  });
  public filter$: Observable<Filter> = this._filter$.asObservable();
  private _filterOptions$: BehaviorSubject<FilterOptions> = new BehaviorSubject(<FilterOptions>{
    contracts: [],
    geocodes: [],
  });
  public filterOptions$: Observable<FilterOptions> = this._filterOptions$.asObservable();
  public hasPerimeter$: Observable<boolean> = this.filter$.pipe(
    map((filter) => this.hasPerimeter(filter))
  );
  public needPerimeter$: Observable<boolean> = this.hasPerimeter$.pipe(
    map((hasPerimeter) => !hasPerimeter)
  );
  constructor(private http: HttpClient) {}

  fetchOptions(): Observable<FilterOptions> {
    return this.http
      .get<FilterOptions>(`${environment.apiUrl}/filters`)
      .pipe(tap((options) => this._filterOptions$.next(Object.freeze(options))));
  }

  getOptions(): FilterOptions {
    return this._filterOptions$.getValue();
  }

  setFilter(filter: Filter) {
    this._filter$.next(filter);
  }

  getFilter() {
    return this._filter$.getValue();
  }

  hasPerimeter(filter?: Filter): boolean {
    filter = filter ?? this.getFilter();
    return filter.contracts.length > 0 || filter.geocodes.length > 0;
  }

  getPerimeter(): Perimeter {
    return {
      contracts: this.getFilter()?.contracts ?? [],
      geocodes: this.getFilter()?.geocodes ?? [],
    };
  }

  toParams() {
    const filter = this.getFilter();
    return {
      contracts: filter.contracts.length ? filter.contracts : null,
      geocodes: filter.geocodes.length ? filter.geocodes : null,
    };
  }
}
